import mock from '../mock'
import { paginateArray, sortCompare } from '../utils'

const data = {
  invoices: [
    
    {
      id: 5017,
      codigo: 4542,
      checklists: 13,
      checkinInicial: '24 Feb 2020',
      registration_date: '24 Feb 2020',
      client: {
        address: '093 Jonathan Camp Suite 953',
        company: 'Allen Group Ltd',
        responsavel: 'Allen Group Ltd',
        companyEmail: 'roydavid@bailey.com',
        country: 'Netherlands',
        contact: '(917) 984-2232',
        name: 'Daniel Marshall PhD'
      },
      model: 'VOYAGE',
      service: 'UI/UX Design & Development',
      total: 3102,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro1.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Compatível',
      balance: '-$153',
      checkinFinal: '25 Aug 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5018,
      codigo: 4865,
      checklists: 32,
      checkinInicial: '29 Feb 2020',
      registration_date: '29 Fev 2020',
      client: {
        address: '4735 Kristie Islands Apt. 259',
        company: 'Chapman-Schneider LLC',
        responsavel: 'Chapman-Schneider LLC',
        companyEmail: 'baldwinjoel@washington.com',
        country: 'Cocos (Keeling) Islands',
        contact: '(670) 409-3703',
        name: 'Randy Rich'
      },
      model: 'VOYAGE',
      service: 'UI/UX Design & Development',
      total: 2483,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro5.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '10 Jul 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5019,
      codigo: 5966,
      checklists: 65,
      checkinInicial: '07 Aug 2019',
      registration_date: '07 Ago 2019',
      client: {
        address: '92218 Andrew Radial',
        company: 'Mcclure, Hernandez and Simon Ltd',
        responsavel: 'Mcclure, Hernandez and Simon Ltd',
        companyEmail: 'psmith@morris.info',
        country: 'Macao',
        contact: '(646) 263-0257',
        name: 'Mrs. Jodi Chapman'
      },
      model: 'VOYAGE',
      service: 'Unlimited Extended License',
      total: 2825,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro8.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Compatível',
      balance: '-$459',
      checkinFinal: '14 Oct 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5020,
      codigo: 7898,
      checklists: 78,
      checkinInicial: '10 May 2019',
      registration_date: '10 Mai 2019',
      client: {
        address: '2342 Michelle Valley',
        company: 'Hamilton PLC and Sons',
        responsavel: 'Hamilton PLC and Sons',
        companyEmail: 'lori06@morse.com',
        country: 'Somalia',
        contact: '(751) 213-4288',
        name: 'Steven Myers'
      },
      model: 'ONIX',
      service: 'Unlimited Extended License',
      total: 2029,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro4.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '28 Mar 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5021,
      codigo: 4652,
      checklists: 48,
      checkinInicial: '02 Apr 2019',
      registration_date: '02 Abr 2019',
      client: {
        address: '16039 Brittany Terrace Apt. 128',
        company: 'Silva-Reeves LLC',
        responsavel: 'Silva-Reeves LLC',
        companyEmail: 'zpearson@miller.com',
        country: 'Slovakia (Slovak Republic)',
        contact: '(655) 649-7872',
        name: 'Charles Alexander'
      },
      model: 'ONIX',
      service: 'Software Development',
      total: 3208,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro4.jpg').default,
      status: 'Alugado',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '06 Sep 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5022,
      codigo: 7893,
      checklists: 95,
      checkinInicial: '02 May 2019',
      registration_date: '02 Mai 2019',
      client: {
        address: '37856 Olsen Lakes Apt. 852',
        company: 'Solis LLC Ltd',
        responsavel: 'Solis LLC Ltd',
        companyEmail: 'strongpenny@young.net',
        country: 'Brazil',
        contact: '(402) 935-0735',
        name: 'Elizabeth Jones'
      },
      model: 'ONIX',
      service: 'Software Development',
      total: 3077,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro3.jpg').default,
      status: 'Alugado',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '09 May 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5023,
      codigo: 5263,
      checklists: 47,
      checkinInicial: '23 Mar 2020',
      registration_date: '23 Mar 2020',
      client: {
        address: '11489 Griffin Plaza Apt. 927',
        company: 'Munoz-Peters and Sons',
        responsavel: 'Munoz-Peters and Sons',
        companyEmail: 'carrietorres@acosta.com',
        country: 'Argentina',
        contact: '(915) 448-6271',
        name: 'Heidi Walton'
      },
      model: 'ONIX',
      service: 'Software Development',
      total: 5578,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro9.png').default,
      status: 'Disponível',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '23 Jul 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5024,
      codigo: 4577,
      checklists: 15,
      checkinInicial: '28 Sep 2019',
      registration_date: '28 Set 2019',
      client: {
        address: '276 Michael Gardens Apt. 004',
        company: 'Shea, Velez and Garcia LLC',
        responsavel: 'Shea, Velez and Garcia LLC',
        companyEmail: 'zjohnson@nichols-powers.com',
        country: 'Philippines',
        contact: '(817) 700-2984',
        name: 'Christopher Allen'
      },
      model: 'ONIX',
      service: 'Software Development',
      total: 2787,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro1.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '25 Set 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5025,
      codigo: 6399,
      checklists: 95,
      checkinInicial: '21 Fev 2020',
      registration_date: '21 Fev 2020',
      client: {
        address: '633 Bell Well Apt. 057',
        company: 'Adams, Simmons and Brown Group',
        responsavel: 'Adams, Simmons and Brown Group',
        companyEmail: 'kayla09@thomas.com',
        country: 'Martinique',
        contact: '(266) 611-9482',
        name: 'Joseph Oliver'
      },
      model: 'GOL',
      service: 'UI/UX Design & Development',
      total: 5591,
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro2.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '07 Jun 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5026,
      codigo: 4141,
      checklists: 32,
      checkinInicial: '24 Mai 2019',
      registration_date: '24 Mai 2019',
      client: {
        address: '1068 Lopez Fall',
        company: 'Williams-Lawrence and Sons',
        responsavel: 'Williams-Lawrence and Sons',
        companyEmail: 'melvindavis@allen.info',
        country: 'Mexico',
        contact: '(739) 745-9728',
        name: 'ARGO'
      },
      model: 'ARGO',
      service: 'Template Customization',
      total: 'MZU-4408',
      plate: 'MZU-4408',
      avatar: require('@src/assets/images/carros/carro6.jpg').default,
      status: 'Disponível',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '22 Out 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5027,
      codigo: 7899,
      checklists: 95,
      checkinInicial: '13 Jan 2020',
      registration_date: '13 Jan 2020',
      client: {
        address: '86691 Mackenzie Light Suite 568',
        company: 'Deleon Inc LLC',
        responsavel: 'Deleon Inc LLC',
        companyEmail: 'gjordan@fernandez-coleman.com',
        country: 'Costa Rica',
        contact: '(682) 804-6506',
        name: 'GOL'
      },
      model: 'GOL',
      service: 'Template Customization',
      total: 'MZU-0828',
      plate: 'MZU-0828',
      avatar: require('@src/assets/images/carros/carro9.png').default,
      status: 'Alugado',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '04 Fev 2020',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5028,
      codigo: 5644,
      checklists: 15,
      checkinInicial: '18 Mai 2019',
      registration_date: '18 Mai 2019',
      client: {
        address: '86580 Sarah Bridge',
        company: 'Farmer, Johnson and Anderson Group',
        responsavel: 'Farmer, Johnson and Anderson Group',
        companyEmail: 'robertscott@garcia.com',
        country: 'Cameroon',
        contact: '(775) 366-0411',
        name: 'PALIO'
      },
      model: 'PALIO',
      service: 'Template Customization',
      total: 'MZY-3271',
      plate: 'MZY-3271',
      avatar: require('@src/assets/images/carros/carro6.jpg').default,
      status: 'UsoInterno',
      invoiceHistorico: 'Avarias',
      balance: '$361',
      checkinFinal: '02 Mar 2020',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5029,
      codigo: 1233,
      checklists: 89,
      checkinInicial: '29 Oct 2019',
      registration_date: '29 Oct 2019',
      client: {
        address: '49709 Edwin Ports Apt. 353',
        company: 'Sherman-Johnson PLC',
        responsavel: 'Sherman-Johnson PLC',
        companyEmail: 'desiree61@kelly.com',
        country: 'Macedonia',
        contact: '(510) 536-6029',
        name: 'ONIX'
      },
      model: 'ONIX',
      service: 'Template Customization',
      total: 'MZZ-1520',
      plate: 'MZZ-1520',
      avatar: require('@src/assets/images/carros/carro2.jpg').default,
      status: 'Manutenção',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '25 Ago 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5030,
      codigo: 4221,
      checklists: 23,
      checkinInicial: '07 Apr 2019',
      registration_date: '07 Apr 2019',
      client: {
        address: '3856 Mathis Squares Apt. 584',
        company: 'Byrd LLC PLC',
        responsavel: 'Byrd LLC PLC',
        companyEmail: 'jeffrey25@martinez-hodge.com',
        country: 'Congo',
        contact: '(253) 230-4657',
        name: 'RENEGADE'
      },
      model: 'RENEGADE',
      service: 'Template Customization',
      total: 'MZZ-8236',
      plate: 'MZZ-8236',
      avatar: require('@src/assets/images/carros/carro3.jpg').default,
      status: 'Transferência',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '06 Mar 2020',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5031,
      codigo: 3785,
      checklists: 45,
      checkinInicial: '21 Aug 2019',
      registration_date: '21 Aug 2019',
      client: {
        address: '141 Adrian Ridge Suite 550',
        company: 'Stone-Zimmerman Group',
        responsavel: 'Stone-Zimmerman Group',
        companyEmail: 'john77@anderson.net',
        country: 'Falkland Islands (Malvinas)',
        contact: '(612) 546-3485',
        name: 'UNO ATTRACTIVE '
      },
      model: 'UNO ATTRACTIVE ',
      service: 'Template Customization',
      total: 'MZW-5927',
      plate: 'MZW-5927',
      avatar: require('@src/assets/images/carros/carro8.jpg').default,
      status: 'Translado',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '09 Jun 2019',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5032,
      codigo: 1234,
      checklists: 95,
      checkinInicial: '31 May 2019',
      registration_date: '31 May 2019',
      client: {
        address: '01871 Kristy Square',
        company: 'Yang, Hansen and Hart PLC',
        responsavel: 'Yang, Hansen and Hart PLC',
        companyEmail: 'ywagner@jones.com',
        country: 'Germany',
        contact: '(203) 601-8603',
        name: 'GOL'
      },
      model: 'GOL',
      service: 'Template Customization',
      total: 'NAA-3619',
      plate: 'NAA-3619',
      avatar: require('@src/assets/images/carros/carro12.jpeg').default,
      status: 'Transferência',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '22 Out 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5033,
      codigo: 9989,
      checklists: 55,
      checkinInicial: '12 Jul 2019',
      registration_date: '12 Jul 2019',
      client: {
        address: '075 Smith Views',
        company: 'Jenkins-Rosales Inc',
        responsavel: 'Jenkins-Rosales Inc',
        companyEmail: 'calvin07@joseph-edwards.org',
        country: 'Colombia',
        contact: '(895) 401-4255',
        name: 'KA SE'
      },
      model: 'KA SE',
      service: 'Template Customization',
      total: 'NAE-3136',
      plate: 'NAE-3136',
      avatar: require('@src/assets/images/carros/carro1.jpg').default,
      status: 'Manutenção',
      invoiceHistorico: 'Compatível',
      balance: 0,
      checkinFinal: '22 Mar 2020',
      invoiceChecklist: 'Finalizado'
    },
    {
      id: 5034,
      codigo: 1324,
      checklists: 72,
      checkinInicial: '10 Jul 2019',
      registration_date: '10 Jul 2019',
      client: {
        address: '2577 Pearson Overpass Apt. 314',
        company: 'Mason-Reed PLC',
        responsavel: 'Mason-Reed PLC',
        companyEmail: 'eric47@george-castillo.com',
        country: 'Paraguay',
        contact: '(602) 336-9806',
        name: 'VOYAGE'
        
      },
      model: 'VOYAGE',
      service: 'Unlimited Extended License',
      total: 'MZW-8770',
      plate: 'MZW-8770',
      avatar: require('@src/assets/images/carros/carro1.jpg').default,
      status: 'UsoInterno',
      invoiceHistorico: 'Avarias',
      balance: 0,
      checkinFinal: '22 Out 2019',
      invoiceChecklist: 'Iniciado'
      
    },
    {
      id: 5035,
      codigo: 7456,
      checklists: 85,
      checkinInicial: '20 Jul 2019',
      registration_date: '20 Jul 2019',
      client: {
        address: '1770 Sandra Mountains Suite 636',
        company: 'Foster-Pham PLC',
        responsavel: 'Foster-Pham PLC',
        companyEmail: 'jamesjoel@chapman.net',
        country: 'Western Sahara',
        contact: '(936) 550-1638',
        name: 'ONIX'
      },
      model: 'ONIX',
      service: 'UI/UX Design & Development',
      total: 'MZQ-3663',
      plate: 'MZQ-3663',
      avatar: require('@src/assets/images/carros/carro13.png').default,
      status: 'Disponível',
      invoiceHistorico: 'Compatível',
      balance: '$762',
      checkinFinal: '12 Jun 2019',
      invoiceChecklist: 'Iniciado'
    },
    {
      id: 5036,
      codigo: 2678,
      checklists: 64,
      checkinInicial: '19 Abr 2019',
      registration_date: '19 Abr 2019',
      client: {
        address: '78083 Laura Pines',
        company: 'Richardson and Sons LLC',
        responsavel: 'Richardson and Sons LLC',
        companyEmail: 'pwillis@cross.org',
        country: 'Bhutan',
        contact: '(687) 660-2473',
        name: 'KWID'
      },
      model: 'KWID',
      service: 'Unlimited Extended License',
      total: 'NAA-2182',
      plate: 'NAA-2182',
      avatar: require('@src/assets/images/carros/carro9.png').default,
      status: 'Alugado',
      invoiceHistorico: 'Avarias',
      balance: '-$205',
      checkinFinal: '25 Set 2019',
      invoiceChecklist: 'Finalizado'
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/invoice/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.invoices
    .filter(
      invoice =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
        invoice.invoiceChecklist.toLowerCase() === (status.toLowerCase() || invoice.invoiceChecklist.toLowerCase())  ||

          invoice.client.name.toLowerCase().includes(queryLowered)) &&
        
        invoice.status.toLowerCase() === (status.toLowerCase() || invoice.status.toLowerCase()) 
    )
    .sort(sortCompare('id'))
    .reverse()
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.invoices,
      invoices: paginateArray(filteredData, perPage, page),
      count: filteredData.length
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(config => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
