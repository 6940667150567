// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import CrudLocReducer from '@src/views/gestao/locadoras/store/reducer'
import CrudUsersReducer from '../../views/gestao/usuarios/store/reducer'
import ListReducer from '../../views/gerenciamento/veiculos/store/reducer'
import TabelaReducer from '../../views/gerenciamento/tabela-checklist/store/reducer'
import FaturamentoReducer from '../../views/gerenciamento/faturamento/store/reducer'
import FaturamentoGeralReducer from '../../views/gestao/faturamento/faturamento_mensal/store/reducer'
import RelatorioGeralReducer from '../../views/gestao/relatoriogeral/store/reducer'
import RelatorioReducer from '../../views/gerenciamento/relatorio/store/reducer'
import CrudClientsReducer from '../../views/gerenciamento/clients/store/reducer'
import ProfileReducer from './ProfileReducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  locs: CrudLocReducer,
  users: CrudUsersReducer,
  list: ListReducer,
  tabela: TabelaReducer,
  faturamento: FaturamentoReducer,
  relatorio: RelatorioReducer,
  faturamentogeral: FaturamentoGeralReducer,
  relatoriogeral: RelatorioGeralReducer,
  clients: CrudClientsReducer,
  profile: ProfileReducer

})

export default rootReducer
