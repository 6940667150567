const initialState = {
  users: []
}

const CrudClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENTS': {
      return { ...state, users: action.data }
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default CrudClientsReducer

