const initialState = {
  users: []
}

const CrudUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS': {
            return {...state,
                users: action.data
            }
        }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default CrudUsersReducer

