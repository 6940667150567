const initialState = {
  data: []
}

const TabelaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TABELA': {
      return {...state,
        data: action.data
      }    
    }

    case 'DELETE_INVOICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default TabelaReducer

