const initialState = {
  backup_codes: [],
  totp_uri: ''
}

const ProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'START_2FA': {
      return {
        ...state,
        totp_uri: action.totp_uri,
        backup_codes: action.backup_codes
      }
    }
    case 'SET_BACKUP_CODES': {
      return {
        ...state,
        backup_codes: action.backup_codes
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default ProfileReducer
